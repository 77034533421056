
              @import "@/assets/css/variables.scss";
            

.line {
  display: block;
  line-height: 24px;
}
.text_color {
  color: #055bff;
}
.closeImg {
  width: 22px;
  position: absolute;
  top: 4%;
  right: 2%;
  cursor: pointer;
}
.title_line {
  color: #38438f;
  font-size: 20px;
  margin-bottom: 20px;
  font-family: 'GTWalsheimPro-Bold';
}
