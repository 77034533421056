/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* 火狐 */
input {
  -moz-appearance: textfield;
}
/deep/ {
  #hongkongForm {
    .rate {
      position: relative;
      top: -40px;
    }
  }
}
#rebateWithdraw /deep/ {
  .box {
    background-color: #ffffff;
    box-shadow: $box-shadow;
    margin-bottom: 32px;
  }

  .box_card_top {
    padding: 48px 32px 0;

    .top {
      display: grid;
      grid-template-columns: 32% 32% 32%;
      column-gap: 2%;
    }

    .alert {
      padding-bottom: 32px;
      p {
        font-size: 20px;
        color: #e91545;
        margin: 0 0 40px 25px;
      }
      ul {
        padding-left: 60px;
        max-width: 90%;

        li {
          list-style: disc;
          padding-left: 1em;
          font-size: 16px;
          line-height: 1.44;
          color: $primary-purple;
        }
      }
    }
  }

  .box_card_bottom {
    padding: 48px 32px 32px;

    .form_bottom_row {
      .img_row {
        text-align: center;

        img {
          display: block;

          &.small {
            max-width: 100px;
          }
        }
      }

      .form_row {
        width: 100%;
        max-width: calc(100% - 280px);
      }

      .form_list {
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 4%;
      }

      .form_list_three_columns {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 10px;
      }

      .alert_info {
        font-size: 16px;
        color: #e91545;
        margin-bottom: 30px;
      }
    }

    .form_button {
      text-align: center;
    }

    #netellerForm {
      .el-form-item {
        margin-bottom: 25px;
      }
    }

    #fasapayForm {
      .el-form-item {
        margin-bottom: 25px;
      }
    }

    #skrillForm {
      .el-form-item {
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 1200px) {
  #rebateWithdraw /deep/ .box_card_top .top {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  #rebateWithdraw /deep/ .box_card_bottom .form_bottom_row {
    .img_row,
    .form_row {
      float: none;
    }

    .img_row {
      margin-bottom: 50px;

      img {
        margin: 0 auto;
      }
    }

    .form_row {
      max-width: 100%;
    }

    .form_list {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      column-gap: 5%;
    }
  }
}

@media (max-width: 550px) {
  #rebateWithdraw /deep/ {
    .box_card_bottom,
    .box_card_top {
      padding: 50px 24px 30px;
    }
  }

  #rebateWithdraw /deep/ .box_card_top {
    .top {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    .alert {
      padding-bottom: 0;
      p {
        font-size: 14px;
        margin: 0 0 20px;
      }
      ul {
        padding-left: 30px;
        max-width: 100%;
        li {
          font-size: 12px;
        }
      }
    }
  }
}
