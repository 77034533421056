
              @import "@/assets/css/variables.scss";
            

.el-form-item {
  input {
    position: sticky;
    z-index: 101;
  }

  &.finish /deep/ label {
    font-size: 14px;
    bottom: 43px;
  }
}

/deep/ .el-input__inner:-webkit-autofill,
/deep/ .el-input__inner:-webkit-autofill:hover,
/deep/ .el-input__inner:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px none inset;
  transition: background-color 5000s ease-in-out 0s;
}
