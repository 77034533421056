
              @import "@/assets/css/variables.scss";
            

/deep/ .el-form-item__label {
  font-size: 14px;
  bottom: 45px;
}
/deep/.el-input__inner {
  color: $primary-green;
}
